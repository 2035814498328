import { graphql } from '@retire/gql/__generated__'

export const FUND_SWITCH_FOR_POT_MUTATION = graphql(`
  mutation switchFundForPot($potType: PotType!, $fundId: ID!) {
    switchFundForPot(potType: $potType, fundId: $fundId) {
      id
      annualManagementCharge
      factsheetUrl
      name
      providerName
      riskLevel
    }
  }
`)
