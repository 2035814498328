import { Icons } from '@retire/components/assets/icons'
import { Button } from '@retire/components/Button'
import { ButtonStyleType } from '@retire/constants/button'
import { useTranslationWithOptions } from '@retire/hooks'
import { isActiveSession, signOut } from '@retire/utils'
import type { FC } from 'react'
import { useCallback, useRef, useState } from 'react'

import { useIsUserLive } from '../PageLayout/hooks'
import { LogoContainer } from './LogoContainer'
import { Header } from './presentation'
import { SignOutConfirmation } from './SignOutConfirmation'
import type { THeaderContainer } from './types'

export const HeaderContainer: FC<THeaderContainer> = ({ onLogoClick, onlyLogoVisible }) => {
  const { t } = useTranslationWithOptions('common')
  const [isQuitConfirmModalOn, toggleQuitConfirmationModal] = useState(false)
  const isUserLive = useIsUserLive()
  const shouldShowNavigation = isUserLive && !onlyLogoVisible
  const modalOpenerRef = useRef<HTMLButtonElement>(null)

  const onClick = useCallback(() => toggleQuitConfirmationModal(true), [])
  const onCancel = useCallback(() => toggleQuitConfirmationModal(false), [])

  return (
    <>
      <Header
        content={
          <LogoContainer dataCy="smartLogo" onClick={onLogoClick}>
            <Icons.Logo />
          </LogoContainer>
        }
        shouldShowNavigation={shouldShowNavigation}
      >
        {isActiveSession() && !onlyLogoVisible && (
          <Button ref={modalOpenerRef} buttonStyleType={ButtonStyleType.secondary} onClick={onClick} lockWidth>
            {t('nav.signOut', 'Signout button')}
          </Button>
        )}
      </Header>
      <SignOutConfirmation
        modalOpenerRef={modalOpenerRef}
        isOpen={isQuitConfirmModalOn}
        onCancel={onCancel}
        onConfirm={signOut}
      />
    </>
  )
}
