import { Link } from '@retire/components/Link/presentation'
import { Spacer } from '@retire/components/Spacer'
import { TransList } from '@retire/components/TransList'
import { PotName } from '@retire/constants/pots'
import { toCurrency } from '@retire/utils'
import type { FC, ReactElement } from 'react'
import { useCallback, useRef } from 'react'

import { useFundDetails } from '../useFundDetails'
import { useModalWithTranslation } from '../useModalWithTranslation'
import { useSettings } from '../useSettings'
import { useTranslationWithOptions } from '../useTranslationWithOptions'

export const useFeesModal = (): { FeesModalLink: FC; FeesModal: () => ReactElement | null } => {
  const { Modal: FeesModal, onShowModal } = useModalWithTranslation()
  const modalOpenerRef = useRef<HTMLButtonElement>(null)

  const FeesModalLink: FC = ({ children }) => {
    const { t } = useTranslationWithOptions('common')
    const {
      adminFees: { chargeAmount: adminFeeAmount },
    } = useSettings()
    const {
      fundDetails: { annualManagementCharge: flexibleIncomeAnnualManagementCharge },
      loading: flexibleIncomeFundDetailsLoading,
    } = useFundDetails(PotName.flexibleIncome)
    const flexibleIncomeFee = flexibleIncomeAnnualManagementCharge?.toFixed(2)
    const {
      fundDetails: { annualManagementCharge: laterLifeAnnualManagementCharge },
      loading: laterLifeFundDetailsLoading,
    } = useFundDetails(PotName.laterLife)
    const laterLifeFee = laterLifeAnnualManagementCharge?.toFixed(2)
    const {
      fundDetails: { annualManagementCharge: inheritanceAnnualManagementCharge },
      loading: inheritanceFundDetailsLoading,
    } = useFundDetails(PotName.inheritance)
    const inheritanceFee = inheritanceAnnualManagementCharge?.toFixed(2)
    const {
      fundDetails: { annualManagementCharge: rainyDayAnnualManagementCharge },
      loading: rainyDayFundDetailsLoading,
    } = useFundDetails(PotName.rainyDay)
    const rainyDayFee = rainyDayAnnualManagementCharge?.toFixed(2)

    const onHowWeCalculateYourFees = useCallback(() => {
      if (
        flexibleIncomeFundDetailsLoading ||
        laterLifeFundDetailsLoading ||
        inheritanceFundDetailsLoading ||
        rainyDayFundDetailsLoading
      ) {
        return
      }

      onShowModal({
        title: t('feesModal.title', 'Fees modal title'),
        content: '<0></0>',
        components: [
          <>
            <Spacer bottom="medium" />
            <TransList isOrdered>
              {t(
                'feesModal.steps',
                'Fees modal steps: flexibleIncomeFeePercentage: {{flexibleIncomeFeePercentage}}%, laterLifeFeePercentage: {{laterLifeFeePercentage}}%, inheritanceFeePercentage: {{inheritanceFee}}%, rainyDayFeePercentage: {{rainyDayFee}}%, adminFeeAmount: {{adminFeeAmount}}',
                {
                  flexibleIncomeFeePercentage: flexibleIncomeFee,
                  laterLifeFeePercentage: laterLifeFee,
                  adminFeeAmount: toCurrency(adminFeeAmount),
                  inheritanceFee,
                  rainyDayFee,
                }
              )}
            </TransList>
          </>,
        ],
        modalOpenerRef,
      })
    }, [
      adminFeeAmount,
      flexibleIncomeFee,
      flexibleIncomeFundDetailsLoading,
      inheritanceFee,
      inheritanceFundDetailsLoading,
      laterLifeFee,
      laterLifeFundDetailsLoading,
      rainyDayFee,
      rainyDayFundDetailsLoading,
      t,
    ])

    return (
      <Link ref={modalOpenerRef} as="a" bold inline onClick={onHowWeCalculateYourFees}>
        {children}
      </Link>
    )
  }

  return {
    FeesModalLink,
    FeesModal,
  }
}
