import { Block, ProgressIndicator as BrightProgressIndicator } from '@smartpension/bright__experimental'
import type { FC } from 'react'
import { useMemo } from 'react'

import type { TProgressIndicator } from './types'

export const ProgressIndicator: FC<TProgressIndicator> = ({
  currentStep,
  totalSteps,
  progressText,
  finishedText,
  isFinished,
}) => {
  const label = useMemo(
    () => (isFinished && finishedText ? finishedText : progressText),
    [finishedText, isFinished, progressText]
  )

  return (
    <Block palette={isFinished ? 'baseTwo' : 'baseOne'}>
      <BrightProgressIndicator currentStep={currentStep} totalSteps={totalSteps} message={label} ariaLabel={label} />
    </Block>
  )
}
