import { graphql } from '@retire/gql/__generated__/gql'

export const AVAILABLE_FUNDS_FOR_POT_QUERY = graphql(`
  query getAvailablePotFunds($potType: PotType!) {
    availableFundsForPot(potType: $potType) {
      annualManagementCharge
      default
      description
      factsheetUrl
      growthRate
      id
      name
      riskLevel
      selected
    }
  }
`)
