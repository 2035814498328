import { DateFormat } from '@retire/constants/date'
import { AccountSummaryTemplate } from '@retire/features-shared/Account/templates/AccountSummary'
import { USER_BRIEF_DETAILS_QUERY } from '@retire/gql/user/queries'
import { useQueryWithError } from '@retire/hooks/useQueryWithError'
import { useTranslationWithOptions } from '@retire/hooks/useTranslationWithOptions'
import { paths } from '@retire/navigation/paths'
import { formatDate } from '@retire/utils/date'
import { getAddressString } from '@retire/utils/strings'
import { getFormattedTelephone } from '@retire/utils/telephone'
import type { FC } from 'react'

import { StepWrapper } from '../../components/StepWrapper'
import { EnrolmentStep } from '../../constants'
import { useEnrolmentHeader } from '../../hooks/useEnrolmentHeader'

export const CheckAndConfirm: FC = () => {
  useEnrolmentHeader({ currentStep: EnrolmentStep.checkAndConfirm })
  const { data, loading } = useQueryWithError(USER_BRIEF_DETAILS_QUERY)

  const state = { isCheckAndConfirmOrigin: true }
  const { t } = useTranslationWithOptions('enrolment')

  return (
    <StepWrapper
      title={t('checkAndConfirm.title', 'Check and confirm')}
      nextText={t('general.actions.next', 'Next')}
      backText={t('general.actions.back', 'Back')}
      loading={loading}
      nextUrl={paths.enrolment.lsaOrLsdQuestion}
      backUrl={paths.enrolment.moneyLeftOver}
    >
      <AccountSummaryTemplate
        personal={{
          heading: t('checkAndConfirm.personalDetails.title', 'Personal details title'),
          details: {
            title: data?.user?.title,
            fullName: data?.user && `${data?.user.forename} ${data?.user.surname}`,
            bornOn: data?.user && formatDate(data?.user.bornOn, DateFormat.date),
            gender: data?.user?.gender,
            nino: data?.user?.nationalInsuranceNumber,
          },
          button: {
            path: paths.enrolment.personalDetails,
            state,
            label: t('checkAndConfirm.personalDetails.action', 'Edit personal details'),
          },
        }}
        contact={{
          heading: t('checkAndConfirm.contactDetails.title', 'Contact details'),
          details: {
            address: getAddressString({ ...data?.user }),
            phoneNumber: getFormattedTelephone(data?.user?.telephone, data?.user?.telephoneCountryCode),
          },
          button: {
            path: paths.enrolment.personalDetails,
            state,
            label: t('checkAndConfirm.contactDetails.action', 'Edit contact details'),
          },
        }}
        payment={{
          heading: t('checkAndConfirm.paymentDetails.title', 'Payment details'),
          button: {
            path: paths.enrolment.bankOrBuildingSociety,
            state,
            label: t('checkAndConfirm.paymentDetails.action', 'Edit payment details'),
          },
        }}
        beneficiaries={{
          heading: t('checkAndConfirm.beneficiaries.title', 'Beneficiaries'),
          list: data?.user?.beneficiaries,
          button: {
            path: paths.enrolment.moneyLeftOver,
            label: t('checkAndConfirm.beneficiaries.action', 'Edit beneficiaries'),
          },
        }}
      />
    </StepWrapper>
  )
}
