import { graphql } from '../__generated__/gql'

export const FUND_DETAILS_QUERY = graphql(`
  query getFundDetails($potType: PotType!) {
    fundDetails(potType: $potType) {
      id
      annualManagementCharge
      description
      factsheetUrl
      growthRate
      id
      name
      providerName
      riskLevel
    }
  }
`)
