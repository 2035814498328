import { useMemo } from 'react'

import { useQuerySelector } from '../useQuerySelector'
import { AVAILABLE_FUNDS_FOR_POT_QUERY } from './queries'
import type { TUsePotFunds } from './types'

export const usePotFunds: TUsePotFunds = (potType, skip) => {
  const {
    data: { availableFundsForPot },
    loading,
  } = useQuerySelector(
    {
      query: AVAILABLE_FUNDS_FOR_POT_QUERY,
      options: {
        variables: {
          potType,
        },
        skip,
      },
    },
    data => data,
    { availableFundsForPot: [] }
  )
  const memberSelectedFund = useMemo(
    () => (loading || !availableFundsForPot ? undefined : availableFundsForPot.find(({ selected }) => selected)),
    [availableFundsForPot, loading]
  )
  const hasMultipleFunds = useMemo(() => !loading && availableFundsForPot.length > 1, [availableFundsForPot, loading])
  const sortedByAmcAvailableFunds = useMemo(
    () =>
      // sort funds by AMC ascending
      availableFundsForPot
        .slice()
        .sort(({ annualManagementCharge: amc1 }, { annualManagementCharge: amc2 }) => (amc1 || 0) - (amc2 || 0)),
    [availableFundsForPot]
  )
  const defaultFund = useMemo(
    () =>
      loading || !availableFundsForPot ? undefined : availableFundsForPot.find(({ default: isDefault }) => isDefault),
    [availableFundsForPot, loading]
  )

  return useMemo(
    () => ({
      loading,
      availableFunds: sortedByAmcAvailableFunds,
      currentFund: memberSelectedFund || defaultFund,
      memberSelectedFund,
      hasMultipleFunds,
    }),
    [defaultFund, hasMultipleFunds, loading, memberSelectedFund, sortedByAmcAvailableFunds]
  )
}
